import React from 'react';
import QuestionPools, { QuestionPool as QuestionPoolType } from '../data/questions/QuestionPools';
import { Button, Checkbox, FormControlLabel, FormGroup, TextField, Typography, Box, Paper, Tooltip } from '@mui/material';
import Quiz from './Quiz';

const Overview: React.FC = () => {
    const [selectedMaxQuestions, setSelectedMaxQuestions] = React.useState<number>(0);
    const [selectedQuestionPools, setSelectedQuestionPools] = React.useState<QuestionPoolType[]>([]);
    const [isQuizStarted, setIsQuizStarted] = React.useState<boolean>(false);

    return (
        <Box>
            <Paper sx={{ padding: 3 }}>
                {
                    isQuizStarted
                        ? (
                            <Quiz
                                questionPools={selectedQuestionPools}
                                maxQuestions={selectedMaxQuestions}
                                onFinish={() => {
                                    setIsQuizStarted(false);
                                    setSelectedQuestionPools([]);
                                }}
                            />
                        ) : (
                            <>
                                <TextField
                                    label="Maximale Anzahl an Fragen (0 = keine Beschränkung)"
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    value={selectedMaxQuestions}
                                    onChange={(event) => setSelectedMaxQuestions(Number(event.target.value))}
                                    sx={{ marginBottom: 2 }}
                                />
                                <Typography variant="body1" component="div" gutterBottom>
                                    Welche Fragen Pools soll das Quiz enthalten?
                                </Typography>
                                <FormGroup>
                                    {
                                        QuestionPools.map((questionPool, index) => (
                                            <Tooltip key={index} title={questionPool.description} arrow>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectedQuestionPools.some(e => e.name === questionPool.name)}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (event.target.checked) {
                                                                    setSelectedQuestionPools([...selectedQuestionPools, questionPool]);
                                                                } else {
                                                                    setSelectedQuestionPools(selectedQuestionPools.filter(e => e.name !== questionPool.name));
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={`${questionPool.name} (${questionPool.questions.length} Fragen)`}
                                                />
                                            </Tooltip>
                                        ))
                                    }
                                </FormGroup>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={selectedQuestionPools.length < 1}
                                    onClick={() => setIsQuizStarted(true)}
                                    sx={{ marginTop: 2 }}
                                >
                                    Quiz starten
                                </Button>
                            </>
                        )
                }
            </Paper>
        </Box>
    );
};

export default Overview;
