import React from 'react';
import {Question as QuestionType} from '../data/questions/Question';
import {Card, CardContent, Typography, Button, List, ListItem, Checkbox, FormControlLabel} from '@mui/material';

interface QuestionProps {
    question: QuestionType;
    onAnswer: (answer: number[]) => void;
}

const Question: React.FC<QuestionProps> = (props: QuestionProps) => {
    const [selectedAnswers, setSelectedAnswers] = React.useState<Array<number>>([]);

    const onAnswer = () => {
        const givenAnswers = selectedAnswers;

        setSelectedAnswers([]);

        props.onAnswer(givenAnswers);
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                    {props.question.question}
                </Typography>
                {
                    (props.question.attachments ?? []).map((attachment, index) => {
                        if (attachment.type === 'image') {
                            return (
                                <img alt="question attachment" width="100%" height="100%" key={index} src={attachment.value} />
                            );
                        }

                        return null;
                    })
                }
                <List>
                    {props.question.options.map((option, index) => (
                        <ListItem key={option}>
                            {
                                props.question.answers.length > 1
                                    ? (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={selectedAnswers.filter(e => e === index).length >= 1}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (event.target.checked) {
                                                            setSelectedAnswers([...selectedAnswers, index]);
                                                        } else {
                                                            setSelectedAnswers([...selectedAnswers.filter(e => e !== index)]);
                                                        }
                                                    }}
                                                />
                                            }
                                            label={option}
                                        />
                                    ) : (
                                        <Button variant="contained" onClick={() => props.onAnswer([index])} fullWidth>
                                            {option}
                                        </Button>
                                    )
                            }
                        </ListItem>
                    ))}
                    {
                        props.question.answers.length > 1 ? <Button variant="contained" fullWidth disabled={selectedAnswers.length < 1} onClick={() => onAnswer()}>Nächste Frage</Button> : null
                    }
                </List>
            </CardContent>
        </Card>
    );
};

export default Question;