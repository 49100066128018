import {Question} from './Question';

const questions: Question[] = [
    {
        question: "Which of the following is NOT an example of an operational decision suitable for DMN?",
        options: [
            "Determine risk approval if a credit limit is exceeded",
            "Estimating shipping charges and determine discounts",
            "Decide whether to introduce DMN in your organization",
            "Assign the tasks in a process based on the skills",
        ],
        answers: [2],
    },
    {
        question: "DMN tables are merely diagrams to show business rule logic, they are not executable.",
        options: [
            "True",
            "False",
        ],
        answers: [1],
    },
    {
        question: "DMN tables can have multiple input columns.",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
    {
        question: "DMN is suitable for which use case?",
        options: [
            "Operational decisions that require multiple executions",
            "Strategic unique decisions",
        ],
        answers: [0],
    },
];

export default questions;
