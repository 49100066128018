import React from 'react';
import {Container, createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import Overview from './components/Overview';

const theme = createTheme({
    palette: {
        mode: 'dark'
    }
});

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container
                maxWidth='md'
                sx={{
                    padding: 2
                }}
            >
                <Overview />
            </Container>
        </ThemeProvider>
    );
};

export default App;