import React from 'react';
import {Question as QuestionType} from '../data/questions/Question';
import {Button, Card, CardContent, List, ListItem, Typography} from '@mui/material';

interface ResultProps {
    questions: QuestionType[];
    answers: Array<Array<number>>;
    onFinish: () => void;
}

const Result: React.FC<ResultProps> = (props: ResultProps) => {
    const calculateScore = (): number => {
        let score = 0;

        props.questions.forEach((question, index) => {
            if (checkAnswers(question.answers, props.answers[index])) {
                score++;
            }
        });

        return score;
    };

    const checkAnswers = (correctAnswer: Array<number>, givenAnswer: Array<number>): boolean => {
        if (correctAnswer.length !== givenAnswer.length) {
            return false;
        }

        for (let answer of correctAnswer) {
            if (!givenAnswer.includes(answer)) {
                return false;
            }
        }

        return true;
    };

    return (
        <>
            <Typography variant="h5" component="div" gutterBottom>
                Quiz beendet! Dein Punktestand: {calculateScore()}/{props.questions.length}
            </Typography>
            <Button variant="contained" fullWidth onClick={() => props.onFinish()}>
                Fertig
            </Button>
            <List>
                {props.questions.map((question, index) => (
                    <ListItem key={index}>
                        <Card variant="outlined" sx={{borderColor: checkAnswers(question.answers, props.answers[index]) ? 'green': 'red'}}>
                            <CardContent>
                                <Typography variant="h5" component="div" gutterBottom>
                                    {question.question}
                                </Typography>
                                {
                                    (question.attachments ?? []).map((attachment, index) => {
                                        if (attachment.type === 'image') {
                                            return (
                                                <img alt="question attachment" width="100%" height="100%" key={index} src={attachment.value} />
                                            );
                                        }

                                        return null;
                                    })
                                }
                                <List>
                                    <ListItem>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            Richtige Antwort: {question.answers.map(answer => question.options[answer]).join(' - ')}
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            Deine Antwort: {props.answers[index].map(answer => question.options[answer]).join(' - ')}
                                        </Typography>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </ListItem>
                ))}
            </List>
        </>
    );
};

export default Result;