import React from 'react';
import {Question as QuestionType} from '../data/questions/Question';
import {QuestionPool as QuestionPoolType} from '../data/questions/QuestionPools';
import QuestionComponent from './Question';
import Result from './Result';

interface QuizProps {
    questionPools: QuestionPoolType[];
    maxQuestions: number;
    onFinish: () => void;
}

const Quiz: React.FC<QuizProps> = (props: QuizProps) => {
    const [currentQuiz, setCurrentQuiz] = React.useState<QuestionType[]>([]);
    const [currentQuestion, setCurrentQuestion] = React.useState<number>(0);
    const [answers, setAnswers] = React.useState<Array<Array<number>>>([]);
    const [isQuizFinished, setIsQuizFinished] = React.useState<boolean>(false);

    React.useEffect(() => {
        const questions: QuestionType[] = [];

        props.questionPools.forEach(questionPool => {
            questions.push(...questionPool.questions);
        });

        setCurrentQuiz(
            shuffleQuestions(questions).slice(
                0,
                (props.maxQuestions > questions.length || props.maxQuestions === 0) ? questions.length : props.maxQuestions
            )
        );
    }, [props.questionPools, props.maxQuestions]);

    const shuffleQuestions = (questions: QuestionType[]) => {
        for (let i = questions.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [questions[i], questions[j]] = [questions[j], questions[i]];
        }
        return questions;
    }
    const onAnswer = (answer: number[]) => {
        setAnswers([...answers, answer]);

        if (currentQuestion + 1 === currentQuiz.length) {
            setIsQuizFinished(true);
        } else {
            setCurrentQuestion(currentQuestion + 1);
        }
    };

    if (currentQuiz.length <= 1) {
        return null;
    }

    return (
        <>
            <h1>{props.questionPools.map(questionPool => questionPool.name).join(' - ')} ({currentQuestion + 1}/{currentQuiz?.length})</h1>
            {
                isQuizFinished
                    ? <Result questions={currentQuiz} answers={answers} onFinish={() => props.onFinish()} />
                    : <QuestionComponent
                        question={currentQuiz[currentQuestion]}
                        onAnswer={(answer) => onAnswer(answer)}
                    />
            }
        </>
    );
};

export default Quiz;