import {Question} from './Question';

const questions: Question[] = [
    {
        question: "The Console is only available with which edition of Camunda 8?",
        options: [
            "Camunda 8 Self-Managed",
            "Camunda 8 SaaS",
        ],
        answers: [1],
    },
    {
        question: "Which of the following is a task management tool enabling human task orchestration?",
        options: [
            "Optimize",
            "Tasklist",
            "Operate",
            "Console",
        ],
        answers: [1],
    },
    {
        question: "Both BPMN and DMN can be executed by the Zeebe Engine?",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
    {
        question: "The Connectors that are available in Camunda 8 are known as?",
        options: [
            "Internal & External",
            "Synchronous & Asynchronous",
            "Inbound & Outbound",
        ],
        answers: [2],
    },
    {
        question: "Which of the following provides visibility into how processes are running, how they have run in the past and how to troubleshoot issues if they arise?",
        options: [
            "Console",
            "Optimize",
            "Zeebe",
            "Operate",
        ],
        answers: [3],
    },
    {
        question: "The Modeler is available as both a Web application and a Desktop application?",
        options: [
            "True",
            "False",
        ],
        answers: [0],
    },
    {
        question: "Which of the following is the process engine powering Camunda 8?",
        options: [
            "Optimize",
            "Console",
            "Operate",
            "Zeebe",
        ],
        answers: [3],
    },
    {
        question: "Which of the following applications are available with Camunda 8 SaaS? Select all that apply.",
        options: [
            "Modeler",
            "Operate",
            "Designer",
            "Monitor",
            "Miner",
            "Optimize",
        ],
        answers: [0, 1, 5],
    },
    {
        question: "A customer wishing to host Camunda 8 on their own infrastructure should choose?",
        options: [
            "Camunda 8 SaaS",
            "Camunda 8 Self-Managed",
        ],
        answers: [1],
    },
    {
        question: "Which of the following is an intelligence solution that leverages process execution data to continuously provide actionable insights?",
        options: [
            "Zeebe",
            "Operate",
            "Console",
            "Optimize",
        ],
        answers: [3],
    },
];

export default questions;